.App {
  text-align: center;
}

@font-face {
  font-family: GilroyLight;
  src: url("./assets/fonts/Gilroy-Light.otf");
}

@font-face {
  font-family: GilroyBold;
  src: url("./assets/fonts/Gilroy-ExtraBold.otf");
}

body {
  overscroll-behavior: none;
  font-family: "GilroyLight", sans-serif;
}

.video-react .video-react-big-play-button {
  display: none !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.sweet-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  width: 100%;
}

.css-1ioq6a {
  width: 20px !important;
  height: 20px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.snackbar-success {
  background-color: green;
  color: black;
}

.snackbar-error {
  background-color: red;
  color: white;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.tab-bar span {
  z-index: 1 !important;
}

.App-link {
  color: #61dafb;
}

.MuiCheckbox-colorSecondary.Mui-checked,
.MuiRadio-colorSecondary.Mui-checked {
  color: #3ec7e6 !important;
}

.button-back {
  display: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.MuiListItem-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.sidebar {
  min-width: 300px;
  background: transparent;
  color: black;
  padding: 0px;
}

.sidebar-menu-list {
  width: 100%;
  padding: 0px !important;
}

.sidebar-menu-list-item {
  padding: 0px !important;
}

.sidebar-menu-list-item-profile {
  padding: 0px !important;
  padding-top: 10px !important;
  background-image: linear-gradient(
    to top,
    #7d55c8,
    #7851c7,
    #724dc6,
    #6c49c6,
    #6645c5,
    #6142c4,
    #5c40c3,
    #563dc2,
    #513ac0
  );
  margin-bottom: 0px !important;
  margin-left: 20px;
}

.menu-list {
  margin-top: 40px;
}

.sidebar-profile-image-holder {
  position: absolute;
  bottom: -20px;
  left: 16px;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background: white;
}

.sidebar-profile-name-holder {
  position: absolute;
  bottom: 0px;
  left: 110px;
  padding-bottom: 10px;
  width: 52%;
}

.sidebar-profile-name-holder .name {
  font-family: "GilroyLight", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: white;
}

.sidebar-profile-name-holder .state {
  font-family: "GilroyLight", sans-serif;
  font-weight: 200;
  font-size: 14px;
  color: white;
}

.sidebar-profile-name-holder .arrow {
  position: absolute !important;
  bottom: 15px;
  padding: 0px;
  right: 10px;
  color: #ffffffc4;
  background: transparent;
  font-size: 32px !important;
  font-family: "GilroyLight", sans-serif;
  border: 0px;
  display: none;
}

.home-profile-image-holder {
  position: relative;
  left: 16px;
  width: 80px;
  top: 10px;
  height: 80px;
  border-radius: 65px;
  background: white;
  border: 7px solid white;
}

.notification-profile-image-holder {
  position: relative;
  top: 10px;
  left: 16px;
  width: 45px;
  height: 45px;
  border-radius: 80px;
  background: white;
  border: 2px solid white;
}

.notification-message-holder {
  position: relative;
  left: 28px;
  text-align: left;
  width: 60%;
  top: 5px;
}

.home-profile-image-edit {
  position: absolute;
  top: 49px;
  left: 49px;
  background: #61dafb;
  padding: 5px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 4px solid white;
  cursor: pointer;
}

.home-profile-name-holder {
  position: relative;
  top: -70px;
  left: 128px;
  text-align: left;
  padding-bottom: 10px;
  width: 60%;
}

.home-profile-name-holder .name {
  font-family: "GilroyLight", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: white;
}

.home-profile-name-holder .state {
  font-family: "GilroyLight", sans-serif;
  font-weight: 200;
  font-size: 14px;
  color: white;
}

.home-profile-name-holder .arrow {
  position: absolute !important;
  bottom: 15px;
  padding: 0px;
  right: 10px;
  color: #ffffffc4;
  background: transparent;
  font-size: 32px !important;
  font-family: "GilroyLight", sans-serif;
  border: 0px;
}

.sidebar-menu-list .listItemIcon {
  color: #45c3be;
}

.btn-purple {
  font-family: "GilroyLight", sans-serif;
  font-weight: 400;
  border-radius: 30px !important;
  padding: 10px !important;
  width: 100%;
  font-size: 15px !important;
  background: white !important;
  color: #9013fe !important;
  border: 1px solid #9013fe !important;
}

.btn-purple:active {
  border-radius: 30px !important;
  border: 0px solid white !important;
  padding: 10px !important;
  width: 100%;
  font-size: 15px !important;
  color: white !important;
  background: #9013fe !important;
}

.btn-purple-highligted {
  border-radius: 30px !important;
  border: 0px solid white !important;
  padding: 10px !important;
  width: 100%;
  font-size: 15px !important;
  color: white !important;
  background: #9013fe !important;
}

.btn-purple:hover {
  border-radius: 30px !important;
  border: 0px solid white !important;
  padding: 10px !important;
  width: 100%;
  font-size: 15px !important;
  color: white !important;
  background: #9013fe !important;
}

Link a {
  text-decoration: none !important;
}

.blue-links {
  text-decoration: none;
  color: #3ec7e6;
  font-family: "GilroyLight", sans-serif;
  font-weight: 200;
  font-size: 15px;
}

.profile-div-background {
  background-image: url("./assets/curlyBackground-transparent.png");
  background-size: cover;
  background-position: center;
  height: 250px;
}

.video-mockup {
  background-size: 100% !important;
  background: url("./assets/video-mockup.png") no-repeat center;
}

.video-mockup > div {
  width: 80% !important;
}

.loungeWowzaPlayer {
  width: 645px !important;
}

.lounge-div-background {
  background-image: url("./assets/background.jpg");
  background-position: center;
  height: 100vh;
  background-repeat: no-repeat;
  object-fit: cover;
}

.takeaways-div-background {
  background-color: white;
  background-size: cover;
  background-position: center;
}

.profile-image-holder {
  width: 90px;
  height: 90px;
  border-radius: 60px;
  margin-top: 90px;
}

.verify-input {
  text-align: "center";
}

body {
  background: url("./assets/tablet.png") repeat;
  font-family: "GilroyLight", sans-serif !important;
  background-color: #f6f5f5 !important;
}

.category-card {
  height: 120px;
  background: linear-gradient(
    to top,
    #7c80a0,
    #9a9bb4,
    #b7b7c8,
    #d5d4dd,
    #f2f2f2
  );
}

.card-overlay {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 140px;
}

.mediaCard {
  width: 92% !important;
  padding-left: 4% !important;
  margin-top: -200px;
}

.mediaCardOverlay {
  width: 100%;
  position: relative;
  justify-content: center;
  display: flex;
  height: 200px;
  background: transparent;
}

.overlay-button {
  background: #ffffff4a !important;
  box-shadow: none !important;
  margin-top: 16% !important;
}

.overlay-button-favorite {
  background: #ffffff4a !important;
  box-shadow: none !important;
  margin-top: 16% !important;
}

.overlay-button-img {
  width: 20px;
  height: 20px;
  margin-left: 4px;
}

.overlay-button-favorite-img {
  width: 20px;
  height: 20px;
  margin-left: 4px;
}

.tab-nav {
  width: 100%;
  justify-content: center;
}

.titlebar {
  color: #7c7e82;
  padding: 15px 15px;
  margin-top: 15px;
  background: #e9e9e9;
  font-size: 14px;
  text-align: left;
  font-family: "GilroyLight", sans-serif !important;
  font-weight: 400;
}

.devider {
  width: 100%;
  border-bottom: 1px solid #ccc;
  opacity: 0.2;
}

.list-items {
  border-bottom: 1px solid #ccc;
  padding: 10px 20px 15px !important;
}

.app-bar {
  background: white !important;
  padding: 5px;
  color: black !important;
}

.app-bar-color {
  background: #533bbe !important;
  padding: 5px;
  color: white !important;
}
.app-bar-tranperent {
  background: none !important;
  padding: 5px;
  color: white !important;
}

.card-bottom-icons {
  border: 1px solid #e3e3e3 !important;
  background: white !important;
  box-shadow: none !important;
  right: 0px;
  position: relative;
}

.like-circle-icon {
  border: 1px solid #e3e3e3 !important;
  box-shadow: none !important;
  right: 0px;
  position: relative;
}

.MuiFab-primary {
  color: #fff !important;
  background-color: #9013fe !important;
}

.MuiFab-white {
  color: #000 !important;
  background-color: #fff !important;
}

.MuiFab-red {
  color: #fff !important;
  background-color: #e4000f !important;
}

.MuiFab-gray {
  color: #fff !important;
  background-color: #7c7a7a !important;
}

.star-rating-holder {
  border: 1px solid lightgray;
  border-left: 0px;
  text-align: center;
}

.star-rating-text-holder {
  display: inline-flex;
  font-size: 20px;
  justify-content: center;
  line-height: 2;
  border: 1px solid lightgray;
  border-right: 0px;
  color: gray;
  border-left: 0px;
}

.star-rating-text-holder-no-border {
  display: inline-flex;
  font-size: 19px;
  justify-content: center;
  line-height: 2;
}

.comment-object {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.comment-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.comment-count {
  background: lightgray;
  padding: 6px 16px;
  font-size: 14px;
  border-radius: 10px;
  max-width: 40px;
  position: relative;
  display: inline;
  margin-left: 10px;
  margin-top: 5px;
  height: 19px;
}

.comment-count::before {
  width: 0;
  height: 0;
  content: "U+0290C";
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  border-right: 8px solid #d3d3d3;
  font-size: 0;
  left: -5px;
  position: absolute;
  line-height: 0;
  right: 10px;
  top: 7px;
}

.contentHolderContainer-90vh {
  padding: 0px;
  overflow-y: scroll;
  height: max-content;
}

.conentHolderContainer-50vh {
  padding: 0px;
  overflow-y: scroll;
  height: fit-content;
}

.otp-container {
  margin: 0 auto;
  justify-content: center;
  width: 25%;
}

.otp-input {
  width: 70px !important;
  height: 50px !important;
  font-size: 20px !important;
  border-radius: 8px !important;
  border: 2px solid #dedbdb;
  outline: none !important;
  margin: 5px !important;
}

.otp-input:focus {
  border: 2px solid #9013fe !important;
}

textarea:focus {
  outline-offset: 0px !important;
}

.overlay-loader {
  position: fixed;
  z-index: 99999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.overlay-loader:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.overlay-loader:not(:required) {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.overlay-loader:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  border-radius: 0.5em;
}

.tabSelected {
  background: yellow;
}

.user-type-circle-wrapper {
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.user-type-circle-wrapper + p {
  font-size: 1em;
}

.user-type-image {
  max-width: 65%;
}

Button {
  text-transform: none !important;
}

.mobile {
  display: none;
}

.web {
  display: block;
  margin-top: 25px;
}

.hamburger-Menu {
  display: none !important;
}

.webMenu {
  display: block;
}

.mobileMenu {
  display: none;
}

.share-container {
  padding: 20px;
}

.share-button-container {
  vertical-align: top;
  display: inline-block;
  padding: 0px 10px;
  text-align: center;
}

.share-button {
  cursor: pointer;
}

.share-button:hover:not(:active) {
  opacity: 0.75;
}

.share-button-icon {
  width: 35px;
  height: 35px;
}

.giggles-video .react-player__preview {
  border-radius: 10px;
}

.react-tel-input .form-control {
  margin-top: 0px !important;
  font-size: 1rem !important;
  box-sizing: border-box !important;
  align-items: center !important;
  font-family: "GilroyLight", "Helvetica", "Arial", sans-serif !important;
  line-height: 0px !important;
  height: inherit !important;
  border: none !important;
  padding: 0.625rem 0.563rem 0.625rem 48px !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  border-top: 1px solid #ffffff !important;
  border-left: 1px solid #ffffff !important;
  border-right: 1px solid #ffffff !important;
  font-size: 1rem !important;
  width: 100% !important;
}

.react-tel-input .flag-dropdown {
  border: 0px solid black !important;
}

.react-tel-input .form-control:focus {
  border-bottom: 2px solid #3f51b5 !important;
}

.videoClass {
  height: 150px !important;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  border: 2px solid #757575;
  width: 16px;
  border-radius: 2px;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #3fc7e6;
  border-radius: 2px;
  height: 20px;
  border: 0px solid gray;
  width: 20px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: scale(1.3) rotate(45deg);
  border-radius: 2px;
}

.star-rating-holder span svg {
  font-size: 23px;
}

.ratingIcon {
  font-size: 23px !important;
}

.contentHolderContainer-90vh::-webkit-scrollbar {
  display: none;
}

.tabHolder::-webkit-scrollbar {
  display: none;
}

.tabHolder {
  overflow-x: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.splash-header {
  font-family: sans-serif;
  font-size: 1.8rem;
  color: white;
  text-align: center;
  margin-block-end: 0px;
  margin-block-start: 0px;
}

.splash-header-sup {
  font-size: 12px;
  margin-top: -5px;
}

.splash-sub-header {
  text-align: center;
  padding: 0px;
  margin-block-start: 5px;
  font-size: 17px;
  color: white;
  font-family: sans-serif;
  font-weight: 300;
}

.splash-container {
  padding: 0px 20px;
  margin-top: 15%;
}

.contact-input {
  -webkit-appearance: none;
}

.preview-slider {
  min-height: calc(500px - 28px) !important;
  max-height: calc(500px - 28px) !important;
}

.preview-slider .slick-slider {
  height: 100% !important;
}

.preview-slider .slick-slider .slick-list {
  height: 100% !important;
}

.preview-slider .slick-slider .slick-list .slick-track {
  height: 100% !important;
}

.preview-slider .slick-slider .slick-list .slick-track .slick-slide {
  height: 100% !important;
}

.preview-slider .slick-slider .slick-list .slick-track .slick-slide div {
  height: 100% !important;
}

.preview-slider .slick-slider .slick-list .slick-track .slick-slide div div {
  height: 100% !important;
}

.slick-prev:before,
.slick-next:before {
  color: rgb(144, 19, 254) !important;
  z-index: 10000 !important;
}

.myScheduleCardHeader .MuiCardHeader-content .MuiCardHeader-subheader {
  color: rgba(0, 0, 0, 0.87) !important;
}

.left-boarder {
  border-left: 3px solid gray;
  height: 30px;
}

@media (min-width: 1280px) {
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 1024px !important;
  }

  .left-boarder {
    border-left: 3px solid gray;
    height: 30px;
  }

  .sidebar-menu-list {
    width: 100%;
  }

  .splash-container {
    padding: 0px 20px;
    margin-top: 45%;
  }

  .splash-container {
    padding: 0px 20px;
    margin-top: 45%;
  }
}

@media (min-width: 1600px) {
  .MuiContainer-maxWidthLg {
    max-width: 1120px !important;
  }

  .left-boarder {
    border-left: 3px solid gray;
    height: 30px;
  }

  .ratingIcon {
    font-size: 34px !important;
  }

  .star-rating-holder span svg {
    font-size: 34px;
  }

  .star-rating-text-holder svg {
    font-size: 34px !important;
  }

  .star-rating-text-holder span {
    font-size: 30px !important;
  }
}

@media (max-width: 991px) {
  .sidebar-profile-name-holder .arrow {
    display: block;
  }

  .left-boarder {
    border-left: 3px solid gray;
    height: 30px;
  }

  .webMenu {
    display: none;
  }

  .mobileMenu {
    display: block;
  }

  .web {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

  .hamburger-Menu {
    display: block !important;
  }

  .sidebar-menu-list {
    width: 300px;
  }

  .button-back {
    display: block !important;
  }

  .ratingIcon {
    font-size: 34px !important;
  }

  .star-rating-holder span svg {
    font-size: 34px;
  }

  .star-rating-text-holder svg {
    font-size: 34px !important;
  }

  .star-rating-text-holder span {
    font-size: 30px !important;
  }

  .splash-container {
    padding: 0px 20px;
    margin-top: 45%;
  }
}

@media (max-width: 767px) {
  .contentHolderContainer-90vh {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .left-boarder {
    border-left: 3px solid gray;
    height: 30px;
  }

  .sidebar-menu-list-item-profile {
    margin-left: 0px;
  }

  .cantainer-white {
    background: #fff;
  }

  .star-rating-holder span svg {
    font-size: 32px;
  }

  .star-rating-text-holder svg {
    font-size: 32px !important;
  }

  .star-rating-text-holder span {
    font-size: 28px !important;
  }

  .splash-container {
    padding: 0px 20px;
    margin-top: 35%;
  }
}

@media (max-width: 320px) {
  .mb-xs-10 {
    margin-bottom: 10px !important;
  }

  .left-boarder {
    border-left: 3px solid gray;
    height: 30px;
  }

  .ratingIcon {
    font-size: 12px !important;
  }

  .star-rating-holder span svg {
    font-size: 20px;
  }

  .star-rating-text-holder svg {
    font-size: 20px !important;
  }

  .star-rating-text-holder span {
    font-size: 18px !important;
  }

  .splash-container {
    padding: 0px 20px;
    margin-top: 45%;
  }
}

@media (max-width: 475px) {
  .user-type-circle-wrapper {
    width: 60px;
    height: 60px;
  }

  .user-type-circle-wrapper + p {
    font-size: 0.8em;
  }

  .user-type-image {
    max-width: 65%;
  }

  .MuiContainer-maxWidthLg {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .ratingIcon {
    font-size: 23px !important;
  }

  .star-rating-holder span svg {
    font-size: 20px;
  }

  .star-rating-text-holder svg {
    font-size: 20px !important;
  }

  .star-rating-text-holder span {
    font-size: 18px !important;
  }

  .splash-container {
    padding: 0px 20px;
    margin-top: 55%;
  }
}

@media only screen and (max-width: 480px) {
  .otp-container {
    margin-left: auto;
    margin-right: auto;
    width: 51%;
  }

  .left-boarder {
    border-left: none;
    height: 30px;
  }

  .otp-input {
    width: 45px !important;
    height: 35px !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    border: 2px solid #dedbdb;
    outline: none !important;
    margin: 2px !important;
  }

  .otp-input:focus {
    border: 2px solid #9013fe !important;
  }

  .videoClass {
    height: 150px !important;
  }
  .homeImage {
    width: 100%;
  }
}

@media only screen and (min-width: 480px) {
  .card-overlay {
    height: 180px;
  }

  .left-boarder {
    border-left: none;
    height: 30px;
  }

  .overlay-button {
    width: 60px !important;
    height: 60px !important;
  }

  .overlay-button-favorite {
    width: 40px !important;
    height: 40px !important;
  }

  .videoClass {
    height: 300px !important;
  }
}

@media only screen and (min-width: 600px) {
  .card-overlay {
    height: 240px;
  }

  .left-boarder {
    border-left: 3px solid gray;
    height: 30px;
  }

  .overlay-button {
    width: 90px !important;
    height: 90px !important;
    margin-top: 32%;
  }

  .overlay-button-favorite {
    width: 50px !important;
    height: 50px !important;
  }

  .overlay-button-img {
    width: 40px !important;
    height: 40px !important;
    margin-left: 8px !important;
  }

  .overlay-button-favorite-img {
    width: 25px !important;
    height: 25px !important;
    margin-left: 8px !important;
  }

  .videoClass {
    height: 300px !important;
  }

  .star-rating-holder span svg {
    font-size: 32px;
  }

  .star-rating-holder span svg:hover {
    font-size: 32px;
    color: "#6fdf93";
  }

  .star-rating-text-holder svg {
    font-size: 32px !important;
  }

  .star-rating-text-holder span {
    font-size: 24px !important;
  }

  .splash-container {
    padding: 0px 20px;
    margin-top: 15%;
  }
}

.shrink-text {
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.wrap-long-text {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.MuiTableCell-root {
  font-size: 0.95rem !important;
  padding: 12px !important;
}

.MuiTableCell-head {
  font-weight: bold !important;
  min-width: 100px !important;
}

.MuiTableBody-root > .MuiTableRow-root {
  cursor: pointer !important;
  transition: 0.1s all ease-in;
}

.MuiTableBody-root > .MuiTableRow-root:hover {
  background-color: #f7f7f7 !important;
}

.MuiTableContainer-root::-webkit-scrollbar {
  height: 4px !important;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
  outline: none;
}

.custom-dialog-title {
  font-size: 1.15rem !important;
}

.MuiDialog-paper::-webkit-scrollbar {
  height: 5px !important;
  width: 5px !important;
}

.MuiDialog-paper::-webkit-scrollbar-thumb {
  outline: none;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.custom-dates-field {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  font-size: 1rem;
  line-height: 1.1876em;
  font-weight: 400;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  margin-top: 24px;
}

.custom-field-error {
  color: #f44336 !important;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
}

.dash-card {
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100% !important;
  padding: 15px 5px;
  box-sizing: border-box !important;
}

.dash-card-title {
  font-size: 1rem;
  margin: 0 !important;
  margin-top: 8px !important;
  text-align: center !important;
}

.dash-card-value {
  font-size: 1.5rem;
  margin: 0 !important;
  color: #533bbe;
  font-family: GilroyBold;
}
